/* -- Login Box --*/
#login-box {
    background-color: rgba(255, 255, 255, 0.9);
    min-height: 450px;
    padding: 15px 30px;
    border-radius: 4px;
    margin: 2% 8% 8% 0;
}

#login-box h1 {
    font-weight: bold;
    text-align: center;
    font-size: 26px;
}
#login-inputs {
    position: relative;
    top: 25px;
}

.login-button{
    width: 100%;
    height: 40px;
    font-weight: 400;
    font-size: 18px;
    position: relative;
    top: 25px;
}
#toast-container{
    position: fixed;
    width: 100%;
    top: 1%;
    display: flex;
    justify-content: center;
}
#toast-container > div.chrome-alert.toast-info {
    width: 90%;
    background-color: #ff0000;
    box-shadow: none;
    color: #fff;
    opacity: 1;
    font-size: 1.5em;
    bottom: 0;
    text-align: center;
    display: block;
    position: absolute;
}
#toast-container > div.chrome-alert:before {
    position: fixed;
    font-family: FontAwesome;
    font-size: 24px;
    float: left;
    color: #fff;
    padding-right: 0.5em;
    margin: auto 0.5em auto -1.5em;
}

#toast-container > .chrome-alert.alert-info:before {
    content: '\f05a';
}
#toast-container > .chrome-alert.alert-info:before,
#toast-container > .chrome-alert.alert-info {
    color: #31708f;
}
#toast-container > .chrome-alert a {
    color: rgb(249, 255, 16);
}
#toast-container > .chrome-alert a:hover {
    color: rgb(255, 200, 114);
}

h1 {
    margin: 0;
    color: #777777;
}
span.invalid {
    margin-left: 12%;
}
.error {
    font-size: 16px;
    color: #f44336;
    font-style: italic;
    height: 16px;
}
.errorShow {
    margin: 0px;
}
.login-box {
    margin-top: 50px;
}
input.form-control {
    background: none !important;
    color: #777;
    opacity: 0.7;
}
.form-floating label {
    color: #777 !important;
    left: 20px !important;
}
